<template>
  <v-container fluid class="product-description" v-if="tabs.length > 0">
    <!-- v-tabs per visibilità desktop -->
    <template v-if="!$vuetify.breakpoint.smAndDown">
      <v-container>
        <v-tabs v-model="selectedTab" fixed-tabs>
          <v-tab v-for="(tab, idx) in tabs" :key="idx" :class="tab.cssClass">
            <v-flex :class="idx + 1 < tabs.length ? 'divider' : ''">{{
              tab.title
            }}</v-flex>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item
            v-for="tab in tabs"
            :key="tab.title"
            :class="tab.cssClass"
          >
            <div
              v-for="item in tab.items"
              :key="item.title"
              class="pb-4"
              :class="item.cssClass"
            >
              <h4 class="text-uppercase" v-if="item.title">{{ item.title }}</h4>
              <div class="content" v-for="path in item.paths" :key="path.key">
                <component
                  :is="path.template || 'DefaultTpl'"
                  :content="path.content"
                  :append="path.append"
                />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </template>

    <template v-else>
      <v-expansion-panels
        class="mb-2"
        accordion
        flat
        tile
        :focusable="selectedTab"
      >
        <v-expansion-panel
          v-for="tab in tabs"
          :key="tab.title"
          :class="tab.cssClass"
        >
          <v-expansion-panel-header class="pa-0">
            <h4 v-html="tab.title"></h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-2">
            <div
              v-for="item in tab.items"
              :key="item.title"
              class="pb-4"
              :class="item.cssClass"
            >
              <h4 v-if="item.title">{{ item.title }}</h4>
              <div v-for="path in item.paths" :key="path.key">
                <component
                  :is="path.template || 'DefaultTpl'"
                  :content="path.content"
                  :append="path.append"
                />
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-container>
</template>

<script>
import {
  DefaultTpl,
  AnchorTpl,
  LabelsTpl,
  ItemsMapTpl,
  TableTpl,
  NameAndValueTpl,
  ValueTpl,
  WarningsTpl,
  ListTpl
} from "./templates";
import get from "lodash/get";
import kebabCase from "lodash/kebabCase";

export default {
  name: "ProductDescription",
  /* eslint-disable vue/no-unused-components */
  components: {
    DefaultTpl,
    AnchorTpl,
    LabelsTpl,
    ItemsMapTpl,
    TableTpl,
    NameAndValueTpl,
    ValueTpl,
    WarningsTpl,
    ListTpl
  },
  data() {
    return {
      selectedTab: null,
      productDescriptionTabs: [
        {
          title: "Descrizione",
          cssClass: "description",
          items: [
            {
              title: "Denominazione di vendita:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.SALES_DESCRIPTION"]
            },
            {
              title: "Caratteristiche:",
              cssClass: "producer_info",
              paths: [
                "metaData.product_description.CLAIM",
                "metaData.product_description.FURTHER_DESCRIPTION"
              ]
            },
            {
              title: "Quantità:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.NET_WEIGHT"]
            },
            {
              title: "Peso sgocciolato:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.NET_DRAINED_WEIGHT"]
            },
            {
              title: "Fonte:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.FONTE"]
            },
            {
              title: "Luogo:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.PLACE"]
            },
            {
              title: "Informazioni:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.AUTHORIZED_INDICATIONS"]
            },
            {
              title: "Pezzi:",
              cssClass: "producer_info",
              paths: [
                {
                  data: "metaData.product_description.PIECES",
                  template: "ItemsMapTpl"
                }
              ]
            },

            {
              title: "Origine:",
              cssClass: "producer_info",
              paths: [
                "metaData.product_description.PRIMARY_INGREDIENT_ORIGIN_SIC",
                "metaData.product_description.PRIMARY_INGREDIENT_ORIGIN"
              ]
            },
            {
              title: "Nato in:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.MEAT_BORN_IN"]
            },
            {
              title: "Allevato in:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.MEAT_BRED_IN"]
            },
            {
              title: "Macellato in:",
              cssClass: "producer_info",
              paths: [
                "metaData.product_description.MEAT_SLAUGHTERED_IN",
                "metaData.product_description.MEAT_SLAUGHTERED_APPROVAL_NUMBER"
              ]
            },
            {
              title: "Sezionato in:",
              cssClass: "producer_info",
              paths: [
                "metaData.product_description.MEAT_SECTIONED_NATION",
                "metaData.product_description.MEAT_SLAUGHTERED_APPROVAL_NUMBER"
              ]
            },
            {
              title: "Pescato in:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.FISH_CAUGHT_IN"]
            },
            {
              title: "Zona FAO:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.FISH_FAO_ZONE"]
            },
            {
              title: "Categoria degli attrezzi da pesca:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.FISH_FISHING_GEAR"]
            },
            {
              title: "Categoria:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.CATEGORY_SIC"]
            },
            {
              title: "Calibro:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.CALIBER_SIC"]
            },
            {
              title: "Varietà:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.VARIETY_SIC"]
            },
            {
              title: "Stile di vita:",
              cssClass: "producer_info",
              paths: [
                {
                  data: "metaData.product_description.LIFESTYLE",
                  template: "LabelsTpl"
                }
              ]
            },
            {
              title: "Zona di produzione:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.PRODUCTION_ZONE"]
            },
            {
              title: "Vitigno:",
              cssClass: "producer_info",
              paths: [
                {
                  data: "metaData.product_description.GRAPE_VARIETY",
                  template: "ValueTpl"
                }
              ]
            },
            {
              title: "Colore:",
              cssClass: "producer_info",
              paths: [
                {
                  data: "metaData.product_description.COLOUR",
                  template: "ValueTpl"
                }
              ]
            },
            {
              title: "Profumo:",
              cssClass: "producer_info",
              paths: [
                {
                  data: "metaData.product_description.SMELL",
                  template: "ValueTpl"
                }
              ]
            },
            {
              title: "Sapore:",
              cssClass: "producer_info",
              paths: [
                {
                  data: "metaData.product_description.TASTE",
                  template: "ValueTpl"
                }
              ]
            },
            {
              title: "Certificazioni:",
              cssClass: "producer_info",
              paths: [
                {
                  data: "metaData.product_description.CERTIFICATIONS",
                  template: "LabelsTpl"
                }
              ]
            },
            {
              title: "Informazioni aggiuntive:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.MARKETING_TEXT"]
            },
            {
              title: "Informazioni produttore:",
              cssClass: "producer_info",
              paths: [
                {
                  data:
                    "metaData.product_description.FACTORY_PRODUCTION_PACKAGING",
                  template: "ItemsMapTpl"
                }
              ]
            },
            {
              title: "Importato da:",
              cssClass: "producer_info",
              paths: [
                {
                  data: "metaData.product_description.IMPORTED_ADDRESS",
                  template: "ItemsMapTpl"
                }
              ]
            },
            {
              title: "Descrizione multistabilimento:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.MULTI_FACTORIES"]
            },
            {
              title: "Descrizione stabilimento:",
              cssClass: "producer_info",
              paths: ["metaData.product_description.FACTORY_DESCRIPTION"]
            },
            {
              title: "Prodotto in:",
              cssClass: "producer_info",
              paths: [
                {
                  data: "metaData.product_description.MADE_IN",
                  template: "ItemsMapTpl"
                }
              ]
            },
            {
              title: "Informazioni produttore:",
              cssClass: "producer_info",
              paths: [
                "metaData.product_description.BRAND_EUROSPIN",
                "metaData.product_description.BOTTLED_BY"
              ]
            }
          ]
        },
        {
          title: "Ingredienti",
          cssClass: "ingredients",
          items: [
            {
              title: "Ingredienti:",
              cssClass: "ingredients",
              paths: ["metaData.product_description.INGREDIENTS_HTML"]
            },
            {
              title: "Composizione:",
              cssClass: "ingredients",
              paths: ["metaData.product_description.COMPOSITION_HTML"]
            },
            {
              title: "Additivi:",
              cssClass: "ingredients",
              paths: ["metaData.product_description.ADDITIVES"]
            },
            {
              title: "Condito con:",
              cssClass: "ingredients",
              paths: ["metaData.product_description.SEASONED_WITH"]
            },
            {
              title: "Allergeni:",
              cssClass: "ingredients",
              paths: [
                {
                  data: "metaData.product_description.ALLERGENS",
                  template: "LabelsTpl"
                }
              ]
            },
            {
              title: "Può contenere tracce di:",
              cssClass: "ingredients",
              paths: [
                {
                  data: "metaData.product_description.CROSS_CONTAMINATION",
                  template: "LabelsTpl"
                }
              ]
            },
            {
              title: "Titolo alcolometrico:",
              cssClass: "ingredients",
              paths: ["metaData.product_description.ALCOHOLICH_STRENGHT"]
            },
            {
              title: "Energia:",
              cssClass: "ingredients",
              paths: ["metaData.product_description.ENERGY"]
            },
            {
              cssClass: "ingredients",
              paths: [
                {
                  data: "metaData.product_description.DIGITAL_LABEL",
                  template: "AnchorTpl"
                }
              ]
            },
            {
              title: "Confezionato in atmosfera protettiva",
              cssClass: "ingredients",
              paths: [
                {
                  data:
                    "metaData.product_description.CONF_ATMOSFERA_PROTETTIVA",
                  template: "ListTpl"
                }
              ]
            },
            {
              title: "Contiene alcool",
              cssClass: "ingredients",
              paths: ["metaData.product_description.CONTAINS_ALCOOL"]
            },
            {
              title: "Informazioni aggiuntive:",
              cssClass: "ingredients",
              paths: [
                "metaData.product_description.INGREDIENTS_ADDITIONAL_TEXT"
              ]
            },
            {
              title: "Trattamento in superficie:",
              cssClass: "ingredients",
              paths: ["metaData.product_description.FRUIT_SURFACE_TREATMENT"]
            },
            {
              title: "Colore della polpa:",
              cssClass: "ingredients",
              paths: ["metaData.product_description.FRUIT_PULP_COLOR"]
            },
            {
              title: "Piccante:",
              cssClass: "ingredients",
              paths: ["metaData.product_description.FRUIT_SPICY"]
            }
          ]
        },
        {
          title: "Componenti",
          items: [
            {
              title: "Composizione",
              cssClass: "ingredients",
              paths: ["metaData.product_description.COMPOSITION_TEXT"]
            },
            {
              title: "Per alimenti",
              cssClass: "ingredients",
              paths: ["metaData.product_description.FOR_FOOD"]
            }
          ]
        },
        {
          title: "Valori nutrizionali",
          cssClass: "nutritional-info",
          items: [
            {
              cssClass: "nutritional-info",
              paths: [
                {
                  data: "metaData.product_description.NUTRITIONAL_VALUES",
                  template: "TableTpl"
                }
              ]
            },
            {
              title: "*AR = Assunzioni di riferimento di un adulto medio:",
              cssClass: "nutritional-info",
              paths: ["metaData.product_description.AR"]
            },
            {
              title: "Valori nutrizionali medi per:",
              cssClass: "nutritional-info",
              paths: [
                {
                  data: "metaData.product_description.NUTRITION",
                  template: "TableTpl"
                }
              ]
            },
            {
              title: "Vitamine e minerali:",
              cssClass: "nutritional-info",
              paths: [
                {
                  data: "metaData.product_description.VITAMINS_AND_MINERALS",
                  template: "TableTpl"
                }
              ]
            },
            {
              title: "Valori nutritivi di riferimento:",
              cssClass: "nutritional-info",
              paths: ["metaData.product_description.VNR"]
            },
            {
              title: "Componenti analitici:",
              cssClass: "nutritional-info",
              paths: ["metaData.product_description.ANALYTICAL_COMPONENTS"]
            },
            {
              title: "Contiene:",
              cssClass: "nutritional-info",
              paths: [
                {
                  data: "metaData.product_description.NUM_PORTIONS",
                  append: "productDescription.portions"
                }
              ]
            },
            {
              title: "Numero porzioni:",
              cssClass: "nutritional-info",
              paths: [
                "metaData.product_description.NUM_PORTIONS_MULT_CONFECTIONS"
              ]
            },
            {
              title: "Indicazioni nutrizionali:",
              cssClass: "nutritional-info",
              paths: [
                {
                  data: "metaData.product_description.NUTRITIONAL_DECLARATION",
                  template: "ValueTpl"
                }
              ]
            },
            {
              title: "Informazioni aggiuntive:",
              cssClass: "nutritional-info",
              paths: ["metaData.product_description.ADDITIONAL_INFORMATION"]
            },
            {
              title: "Autorizzazione:",
              cssClass: "nutritional-info",
              paths: ["metaData.product_description.AUTHORIZATION"]
            },
            {
              title: "Analisi eseguite da:",
              cssClass: "nutritional-info",
              paths: ["metaData.product_description.ANALYZED_BY"]
            },
            {
              title: "Analisi chimico fisiche:",
              cssClass: "nutritional-info",
              paths: [
                {
                  data:
                    "metaData.product_description.PHYSICO_CHEMICAL_ANALYSIS",
                  template: "TableTpl"
                }
              ]
            }
          ]
        },
        {
          title: "Consigli d'uso e avvertenze",
          cssClass: "producer",
          items: [
            {
              title: "Modalità d'uso:",
              cssClass: "producer",
              paths: [
                {
                  data: "metaData.product_description.HOW_TO_USE",
                  template: "TableTpl"
                },
                {
                  data: "metaData.product_description.HOW_TO_USE_TEXT",
                  template: "DefaultTpl"
                }
              ]
            },
            {
              title: "Lavaggi:",
              cssClass: "producer",
              paths: ["metaData.product_description.WASHING"]
            },
            {
              title: "Capacità tappo/misurino:",
              cssClass: "producer",
              paths: ["metaData.product_description.CUP_CAPACITY"]
            },
            {
              title: "Avvertenze di sicurezza:",
              cssClass: "producer",
              paths: [
                {
                  data: "metaData.product_description.WARNINGS",
                  template: "WarningsTpl"
                }
              ]
            },
            {
              title: "Istruzioni per l'uso:",
              cssClass: "producer",
              paths: ["metaData.product_description.INSTRUCTION_FOR_USE"]
            },
            {
              title: "Razione giornaliera:",
              cssClass: "producer",
              paths: ["metaData.product_description.DAILY_RATION"]
            },
            {
              title: "Modalita' di conservazione:",
              cssClass: "producer",
              paths: ["metaData.product_description.CONSERVAZIONE"]
            },
            {
              title: "Modalita' di conservazione post apertura:",
              cssClass: "producer",
              paths: [
                "metaData.product_description.CONSERVAZIONE_POST_APERTURA"
              ]
            },
            {
              title: "Temperatura di servizio:",
              cssClass: "producer",
              paths: ["metaData.product_description.SERVICE_TEMPERATURE"]
            },
            {
              title: "Per un consumo ideale:",
              cssClass: "producer",
              paths: ["metaData.product_description.IDEAL_CONSUMPTION"]
            },
            {
              title: "Conservazione domestica:",
              cssClass: "producer",
              paths: [
                {
                  data: "metaData.product_description.HOME_PRESERVATION",
                  template: "TableTpl"
                }
              ]
            },
            {
              title: "Ricette:",
              cssClass: "producer",
              paths: ["metaData.product_description.RECIPE"]
            },
            {
              title: "Modalità di preparazione:",
              cssClass: "producer",
              paths: [
                {
                  data: "metaData.product_description.PREPARATION_GUIDELINES",
                  template: "ItemsMapTpl"
                }
              ]
            },
            {
              title: "Periodo post apertura:",
              cssClass: "producer",
              paths: ["metaData.product_description.PAO"]
            }
          ]
        }
      ]
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    productMetadata: {
      type: Object
    },
    ingredientList: {
      type: Object
    }
  },
  computed: {
    tabs() {
      let tabs = [];
      this.productDescriptionTabs.forEach(tabMeta => {
        let items = [];
        tabMeta.items.forEach(parMeta => {
          let paths = [];
          parMeta.paths.forEach(pathMeta => {
            let content = get(this.product, pathMeta.data || pathMeta);
            let append = pathMeta.append;
            // check if content is empty
            if (content && content !== "<p></p>") {
              // check if table has empty cells
              if (pathMeta.template === "TableTpl") {
                let cells = JSON.parse(content).cells;
                console.log(cells);
                let hasContent = cells.find(c => {
                  return c !== null;
                });
                if (!hasContent) {
                  return;
                }
              }
              if (pathMeta.template === "ValueTpl") {
                let value = JSON.parse(content).value;
                if (!value) {
                  return;
                }
              }
              // check if list has no options
              // if (pathMeta.template === "ListTpl") {
              //   try {
              //     let options = JSON.parse(content).options;
              //     if (options.length < 1) {
              //       return;
              //     }
              //   } catch (e) {
              //     return content;
              //   }
              // }
              paths.push({
                key: pathMeta.data || pathMeta,
                content: content,
                template: pathMeta.template,
                append: append
              });
            }
          });
          if (paths.length > 0) {
            items.push({
              title: parMeta.title,
              paths: paths,
              cssClass: get(parMeta, "cssClass", kebabCase(parMeta.title))
            });
          }
        });
        if (items.length > 0) {
          tabs.push({
            title: tabMeta.title,
            items: items,
            cssClass: get(tabMeta, "cssClass", kebabCase(tabMeta.title))
          });
        }
      });

      // additional text at Ingredients tab end
      let ingredientsTab = tabs.find(t => {
        return t.title === "Ingredienti";
      });
      if (ingredientsTab) {
        ingredientsTab.items.push({
          paths: [
            { content: this.$t("productDescription.ingredientsAdditionalInfo") }
          ]
        });
      }
      return tabs;
    }
  }
};
</script>
