<template>
  <div>
    <v-breadcrumbs
      class="hidden-sm-and-down"
      :items="vBreadcrumb"
    ></v-breadcrumbs>
    <v-container
      v-if="
        category.categoryId === product.categoryId && headerBanner.length > 0
      "
    >
      <ProposalSlider :proposals="headerBanner" :key="'header-banner_' + key" />
    </v-container>
    <v-container class="d-flex flex-row align-center">
      <v-spacer></v-spacer>
      <v-btn
        depressed
        class="back"
        v-if="$routerHistory.hasPrevious() && $platform_is_cordova"
        :to="{ path: $routerHistory.previous().path }"
        ><v-icon>mdi-chevron-left</v-icon>Torna indietro</v-btn
      >
    </v-container>

    <v-container fluid v-if="product" class="product-detail px-0">
      <ProductDetail
        v-if="product.productId"
        :product="product"
        :selectedImage="selectedImage"
        :key="'product_detail_' + key"
        @selectImage="selectImage"
      />
      <ProductDescription
        v-if="product.productId"
        :product="product"
        :key="'product_description_' + key + '_' + product.productId"
        :productMetadata="product.metaData"
        :ingredientList="product.ingredientList"
      />
    </v-container>
    <div class="product-detail-slider-container">
      <div class="product-detail-slider">
        <ProductListSlider
          :layout="220"
          :limit="12"
          v-if="product.productId"
          :productId="product.productId"
          :key="product.productId + '_1'"
          title="Ti potrebbero interessare anche:"
          :paginationClass="'swiper-pagination-interesse'"
        />
      </div>
      <div class="product-detail-slider">
        <ProductListSlider
          :layout="223"
          :limit="12"
          v-if="product.productId"
          :productId="product.productId"
          :key="product.productId + '_2'"
          title="Suggeriti per te:"
          :paginationClass="'swiper-pagination-suggeriti'"
        />
      </div>
    </div>
    <v-container v-if="showNoProductCard">
      <v-card
        class="d-flex align-center mb-6 justify-center"
        flat
        height="100"
        tile
      >
        <v-card class="pa-2" flat>
          <h3 class="font-weight-regular login-sub">
            {{ $t("products.noDetailProduct") }}
          </h3>
        </v-card>
      </v-card>
    </v-container>
    <v-container
      v-if="
        category.categoryId === product.categoryId && footerBanner.length > 0
      "
    >
      <ProposalSlider :proposals="footerBanner" :key="'footer-banner_' + key" />
    </v-container>
  </div>
</template>

<script>
import ProductService from "~/service/productService";
import ProductDescription from "@/components/product/ProductDescription";
import ProductDetail from "@/components/product/ProductDetail";
// import ProductImages from "@/components/product/ProductImages";
import ProductListSlider from "@/components/product/ProductListSlider";
import categoryMixin from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";
import ProposalSlider from "@/components/proposal/ProposalSlider";
import { mapProposal } from "~/service/ebsn";
import analyticsService from "~/service/analyticsService";
import join from "lodash/join";
import compact from "lodash/compact";
import clone from "lodash/clone";
import get from "lodash/get";

export default {
  name: "Product",
  mixins: [categoryMixin, deliveryReactive],
  data() {
    return {
      product: {
        productId: null,
        name: "",
        shortDescr: "",
        description: ""
      },
      showNoProductCard: false,
      selectedImage: null,
      key: 1
    };
  },
  components: {
    ProductDescription,
    ProductDetail,
    ProductListSlider,
    ProposalSlider
  },
  computed: {
    ...mapProposal({
      headerBanner: "header-banner",
      footerBanner: "footer-banner"
    }),
    getFullName() {
      return join(
        compact([
          this.product.name,
          this.product.shortDescr,
          this.product.description,
          "in dettaglio"
        ]),
        " "
      );
    },
    vBreadcrumb() {
      let b = clone(this.breadcrumb);
      if (this.product) {
        b.push({
          to: {
            name: "Product",
            params: {
              volantino: this.product.name
            }
          },
          text: this.product.name,
          exact: true
        });
      }
      return b;
    }
  },
  methods: {
    async getProduct() {
      var data = await ProductService.getProductBySlug(this.slug);
      if (data) {
        this.product = data;
        if (this.product.categoryId) {
          this.setCategory(this.product.categoryId);
        }

        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
      } else {
        this.showNoProductCard = true;
      }
    },
    async setCategory(categoryId) {
      this.$store.dispatch("category/setCategory", {
        slug: categoryId,
        reset: false
      });
    },
    async selectImage(image) {
      this.selectedImage = image;
      this.selectedImage.thumb = image.medium;
    },
    async reload() {
      await this.resetFilters(this.category.slug);
      await this.getProduct();
      this.key += 1;
    },
    async setProduct(product) {
      // var data = await ProductService.getProductBySlug(this.slug);
      if (product) {
        this.product = product;
        if (this.product.categoryId) {
          this.setCategory(this.product.categoryId);
        }
        // if (this.product.categoryId) {
        //   this.setCategory(this.product.categoryId);
        // }
        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
        analyticsService.viewProductDetail(this.product);
      }
      this.loading = false;
    }
  },
  // created() {
  //   this.slug = this.$route.params.slug;
  //   this.showNoProductCard = false;
  //   this.getProduct();
  // },
  async mounted() {
    this.slug = this.$route.params.slug;
    this.showNoProductCard = false;
    this.setProduct(this.$route.params.product);
  },
  watch: {
    async $route(to) {
      if (to.params.slug != this.slug) {
        this.slug = to.params.slug;
        this.setProduct(this.$route.params.product);
      }
    }
  },
  metaInfo() {
    return {
      title: get(
        this.product,
        "metaData.product_seo.SEO_TITLE",
        this.getFullName
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.product,
            "metaData.product_seo.SEO_DESCRIPTION",
            "Acquista subito online " +
              this.getFullName +
              " sul nostro sito e ritira gratis nei punti vendita in cui è attivo il servizio."
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: get(this.product, "metaData.product_seo.SEO_KEYWORDS")
        }
      ]
    };
  }
};
</script>
