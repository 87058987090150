<template>
  <div v-if="rendered.length">
    <div v-for="item in rendered" :key="item.key">
      <div v-for="(warning, idx) in warnings" :key="idx">
        <!-- <div v-html="`${warning.name}:`" class="font-weight-bold" /> -->
        <div v-if="warning.value" v-html="warning.value" />
        <div>
          <img
            class="warning_img"
            v-for="(img, idy) in warning.assets"
            :key="idy"
            :src="img.thumbnailUrl"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.warning_img {
  width: 40px;
}
</style>
<script>
export default {
  props: {
    content: { type: String, required: true }
  },
  data() {
    return { rendered: [] };
  },
  computed: {
    warnings() {
      let keyList = global.config.productDescriptionWarningsKeyList;

      let deserializedString = this.rendered;
      let itemsList = [];
      let warningsOk = false;
      for (let index = 0; index < deserializedString.length; index++) {
        let element = deserializedString[index]["items-map"];
        for (let key in element) {
          if (keyList.includes(key)) {
            if (
              element[key].value != null ||
              (element[key].assets && element[key].assets.length > 0)
            ) {
              warningsOk = true;
              itemsList.push({
                code: element[key].code,
                name: element[key].name,
                value: element[key].value,
                assets: element[key].assets
              });
            }
          }
        }
      }

      var result = [];

      for (var k = 0; k < keyList.length; k++) {
        for (var e = 0; e < itemsList.length; e++) {
          if (itemsList[e].code == keyList[k]) {
            if (itemsList[e].code == "productIdentifier") {
              itemsList[e].value =
                itemsList[e].name.toUpperCase() + ": " + itemsList[e].value;
            }
            result.push(itemsList[e]);
          }
        }
      }

      if (!warningsOk) result = null;
      // if (itemsList.length < 2) {
      //   return itemsList[0].value;
      // }
      return result;
    }
  },
  created() {
    this.rendered = JSON.parse(this.content);
  }
};
</script>
