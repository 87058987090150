<template>
  <div class="d-flex flex-column" v-if="content">
    {{ $t("productDescription.digitalLabel") }}
    <a @click="clicked" target="_blank" :href="content" class="anchor_tpl">{{
      content
    }}</a>
  </div>
</template>
<style>
.anchor_tpl {
  word-break: break-all;
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
export default {
  mixins: [clickHandler],
  props: {
    content: { type: String, required: true }
  },
  created() {
    try {
      this.content = JSON.parse(this.content);
    } catch (e) {
      this.content;
    }
  }
};
</script>
