<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <div class="product-class mr-4" v-bind="attrs" v-on="on">
        <img v-if="src" aria-hidden :alt="pClass.name" :src="src" />
      </div>
    </template>
    <span>{{ pClass.name }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "ProductClass",
  props: ["pClass"],
  computed: {
    src() {
      if (!this.pClass.iconSource) return null;
      return this.pClass.iconSource;
    }
  }
};
</script>
